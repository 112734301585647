import axios from 'axios';
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import Common from './Helpers/Common';
import { v4 as uuidv4 } from 'uuid';

// Initialize Shopify App Bridge
const app = createApp({
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY, // Add this to your .env file
  host: new URLSearchParams(window.location.search).get('host') || Common.getShop(),
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// Add interceptors to include session token
axiosInstance.interceptors.request.use(async (config) => {
  try {
    const sessionToken = await getSessionToken(app); // Fetch session token
    config.headers['Authorization'] = `Bearer ${sessionToken}`;
  } catch (error) {
    console.error("Failed to fetch session token", error);
  }

  // Include other custom headers
  config.headers['X-Primary-Domain'] = Common.getShop();
  config.headers['X-Primary-Token'] = uuidv4();
  config.headers['X-WS-App-Version'] = "238";

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
