import React from "react";
import { Routes, Route, useParams, useNavigate, Navigate } from 'react-router-dom';
import Home from './Components/Home';
import Dashboard from './Components/Dashboard';
import CommonHelper from '../../Helpers/Common';
import RegularDiscountList from './Components/RegularDiscountList';
import CreateRegularDiscount from './Components/CreateRegularDiscount';
import VolumeDiscountList from './Components/VolumeDiscountList';
import CreateVolumeDiscount from './Components/CreateVolumeDiscount';
import MinOrderControl from './Components/MinOrderControl';
import CreateMinOrderControl from './Components/CreateMinOrderControl';
import ManageShipping from './Components/ManageShipping';
import CreateManageShipping from './Components/CreateManageShipping';
import CartLevelDiscountList from './Components/CartLevelDiscountList';
import CreateCartLevelDiscount from './Components/CreateCartLevelDiscount';
import Configuration from './Components/Configuration';
import Settings from './Components/GeneralSettingsDashboard';
import ManageCustomerList from './Components/ManageCustomerList';
import ManageCustomerDashboard from './Components/ManageCustomerDashboard';
import CustomersList from './Components/CustomersList';
import CustomerDetail from './Components/CustomerDetail';
import CreateWSForm from './Components/CreateWSForm';
import EmailTemplate from './Components/EmailTemplate';
import QuantityControls from './Components/QuantityControls';
import NetTerms from './Components/NetTerms';
import NetTermsLoader from './Components/NetTermsLoader';
import QuickOrderForm from './Components/QuickOrderForm';
import CreateQuickOrderForm from './Components/CreateQuickOrderForm';
import ManageLocks from './Components/ManageLocks';
import CreateLock from './Components/CreateLock';
import ImportRegularDiscount from './Components/ImportRegularDiscount';
import ImportVolumeDiscount from './Components/ImportVolumeDiscount';
import UpdateProductsModal from './Components/UpdateProductsModal';
import VariantPickerForSelectedProducts from './Components/VariantPickerForSelectedProducts';
import UpdateVariantsModal from './Components/UpdateVariantsModal';
import UpdateCollectionsModal from './Components/UpdateCollectionsModal';
import ShippingCountries from './Components/ShippingCountries';
import ManualDraftOrder from './Components/ManualDraftOrder';
import VariantPicker from './Components/VariantPicker';
import UpdateExcludedProducts from './Components/UpdateExcludedProducts';
import UpdateExcludedCollections from './Components/UpdateExcludedCollections';
import ShopInfo from './Components/ShopInfo';
import NotFound from './Components/NotFound';
import InstallationConfigurationList from './Components/InstallationConfigurationList';
import CreateInstallationConfiguration from './Components/CreateInstallationConfiguration';
import LoginToViewPrice from './Components/LoginToViewPrice';
import GetSupport from './Components/GetSupport';
import ManageAddons from './Components/ManageAddons';
import ContactUs from './Components/ContactUs';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

const shop = CommonHelper.getShop();
// const isAdmin = CommonHelper.isAdmin();
export default function Routing (props){	
	// USING: react-router-v6


	// A wrapper function that will call on every route
	// adding additional props to support react-router v4 methods
	const RouteWrapper = (props) => {
		const navigate = useNavigate();
		const extraProps = {
			// pass query params to old functions
			match:{
				params: useParams()
			},

			// in v4, we navigate to url by pushing in history which is npm package
			// in v6, we will use react hooks
			history: {
				push: (url) => {
					url = CommonHelper.mergeQueryParams(url);
					navigate(url);
				},
				replace: (url) => {
					url = CommonHelper.mergeQueryParams(url);
					navigate(url, {replace: true});
				}
			},

			// A replacement to window.location.href, to add additional query param to request
			linkOverride:{
				replace: (url) => {
					url = CommonHelper.mergeQueryParams(url);
					window.location.replace(url);
				},
				push: (url) => {
					url = CommonHelper.mergeQueryParams(url);
					window.location.href = url;
				},
			}
		};
		const Component = props.element;
		return <Component {...props} {...extraProps} />
	}

	return(
		<Routes>
			<Route
				path={"/"}
				element={<RouteWrapper element={Home } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/"}
				element={<RouteWrapper element={Home } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/home"}
				element={<RouteWrapper element={Home } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"/home"}
				element={<RouteWrapper element={Home } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"/dashboard"} // initiate app
				element={<RouteWrapper element={Home } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/dashboard"}
				element={<RouteWrapper element={Dashboard } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/pricing-plans"}
				element={<RouteWrapper element={ ManageAddons } {...props} show="manual" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/pricing-groups"}
				element={<RouteWrapper element={RegularDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"/pricing-groups"}
				element={<RouteWrapper element={RegularDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/regular-discount/list"}
				element={<RouteWrapper element={RegularDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/regular-discount/create"}
				element={<RouteWrapper element={CreateRegularDiscount } {...props} show="create" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/regular-discount/edit/:id"}
				element={<RouteWrapper element={CreateRegularDiscount } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/quantity-breaks"}
				element={<RouteWrapper element={VolumeDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"/quantity-breaks"}
				element={<RouteWrapper element={VolumeDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/volume-discount/list"}
				element={<RouteWrapper element={VolumeDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/volume-discount/create"}
				element={<RouteWrapper element={CreateVolumeDiscount } {...props} show="create" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/volume-discount/edit/:id"}
				element={<RouteWrapper element={CreateVolumeDiscount } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/theme-installations/list"}
				element={<RouteWrapper element={InstallationConfigurationList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/theme-installations/edit/:id"}
				element={<RouteWrapper element={CreateInstallationConfiguration } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/theme-installations/manual/:id"}
				element={<RouteWrapper element={CreateInstallationConfiguration } {...props} show="manual" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				path={BUILD_VERSION_EXTENSION+"/update-plans"}
				element={<RouteWrapper element={ ManageAddons } {...props} show="manual" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				path={"/update-plans"} // without build extention because this will be hit by shopify
				element={<Navigate to={BUILD_VERSION_EXTENSION+"/update-plans"} />}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/contact-us"}
				element={<RouteWrapper element={ContactUs } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/min-order-control"}
				element={<RouteWrapper element={MinOrderControl } {...props} show="list" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/min-order-control/:id"}
				element={<RouteWrapper element={CreateMinOrderControl } {...props} show="edit"shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/add-min-order-control"}
				element={<RouteWrapper element={CreateMinOrderControl } {...props} show="add" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/shipping"}
				element={<RouteWrapper element={ManageShipping } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manage-shipping"}
				element={<RouteWrapper element={ManageShipping } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/add-manage-shipping"}
				element={<RouteWrapper element={CreateManageShipping } {...props} show="add" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manage-shipping/:id"}
				element={<RouteWrapper element={CreateManageShipping } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/cart-discounts/list"}
				element={<RouteWrapper element={CartLevelDiscountList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/cart-discounts/create"}
				element={<RouteWrapper element={CreateCartLevelDiscount } {...props} show="create" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/cart-discounts/edit/:id"}
				element={<RouteWrapper element={CreateCartLevelDiscount } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/config"}
				element={<RouteWrapper element={Configuration } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/settings"}
				element={<RouteWrapper element={Settings } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-customers"}
				element={<RouteWrapper element={ManageCustomerList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-customers/ws-form/list"}
				element={<RouteWrapper element={ManageCustomerList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-customers/archived"}
				element={<RouteWrapper element={ManageCustomerList } {...props} archived={true} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/customers"}
				element={<RouteWrapper element={ManageCustomerDashboard } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/customers-list/:status"}
				element={<RouteWrapper element={CustomersList } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/customer/:id"}
				element={<RouteWrapper element={CustomerDetail } {...props} getFromShopify={false} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/customer/shopify/:id"}
				element={<RouteWrapper element={CustomerDetail } {...props} getFromShopify={true} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/customers/:id"}
				element={<RouteWrapper element={ManageCustomerDashboard } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-customers/ws-form/create"}
				element={<RouteWrapper element={CreateWSForm } {...props} show="create" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-customers/ws-form/:id"}
				element={<RouteWrapper element={CreateWSForm } {...props} show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/:id/:uid"}
				element={<RouteWrapper element={EmailTemplate } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/quantity-controller"}
				element={<RouteWrapper element={QuantityControls } {...props} addon='quantity_controller' show="list" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/add-quantity-controller"}
				element={<RouteWrapper element={QuantityControls } {...props} addon='quantity_controller' show="add" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/edit-quantity-controller/:id"}
				element={<RouteWrapper element={QuantityControls } {...props} addon='quantity_controller' show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/net-terms"}
				element={<RouteWrapper element={NetTermsLoader } {...props} addon="net_term" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/net-terms/settings"}
				element={<RouteWrapper element={NetTerms } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/quick-order-forms"}
				element={<RouteWrapper element={QuickOrderForm } {...props} addon="quick_order_form" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/quick-order-form/add"}
				element={<RouteWrapper element={CreateQuickOrderForm } {...props} addon="quick_order_form" show="add" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/quick-order-form/edit/:id"}
				element={<RouteWrapper element={CreateQuickOrderForm } {...props} addon="quick_order_form" show="edit" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manage-locks"}
				element={<RouteWrapper element={ManageLocks } {...props} addon="managelock" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manage-locks/create"}
				element={<RouteWrapper element={CreateLock } {...props} addon="managelock" show={"add"} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manage-locks/edit/:id"}
				element={<RouteWrapper element={CreateLock } {...props} addon="managelock" show={"edit"} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				path={BUILD_VERSION_EXTENSION+"/login-to-view-price"}
				element={<RouteWrapper element={LoginToViewPrice } {...props} addon="login_to_view_prices" shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/import-regular-discount"}
				element={<RouteWrapper element={ImportRegularDiscount } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/import-volume-discount"}
				element={<RouteWrapper element={ImportVolumeDiscount } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/update-products-modal"}
				element={<RouteWrapper element={UpdateProductsModal } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/selected-products-variant-picker"}
				element={<RouteWrapper element={VariantPickerForSelectedProducts } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/update-variants-modal"}
				element={<RouteWrapper element={UpdateVariantsModal } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/update-collections-modal"}
				element={<RouteWrapper element={UpdateCollectionsModal } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/select-shipping-countries"}
				element={<RouteWrapper element={ShippingCountries } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/manual-draft-order"}
				element={<RouteWrapper element={ManualDraftOrder } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"/manual-draft-order"}
				element={<RouteWrapper element={ManualDraftOrder } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/variant-picker"}
				element={<RouteWrapper element={VariantPicker } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/update-excluded-products"}
				element={<RouteWrapper element={UpdateExcludedProducts } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/update-excluded-collections"}
				element={<RouteWrapper element={UpdateExcludedCollections } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={BUILD_VERSION_EXTENSION+"/storeinfo"}
				element={<RouteWrapper element={ShopInfo } {...props} shopData={props.shopData} shop={shop}/>}
			/>

			<Route
				
				path={BUILD_VERSION_EXTENSION+"/get-support"}
				element={<RouteWrapper element={GetSupport } {...props} shopData={props.shopData} shop={shop}/>}
			/>

			<Route
				
				path={BUILD_VERSION_EXTENSION+"/*"}
				element={<RouteWrapper element={NotFound } {...props} shopData={props.shopData} shop={shop}/>}
			/>
			<Route
				
				path={"*"}
				element={<RouteWrapper element={NotFound } {...props} shopData={props.shopData} shop={shop}/>}
			/>
		</Routes>
	);
}