import React, { Component } from 'react';
import {
  Card, Stack, Checkbox, TextField, RadioButton, Button, Heading, Collapsible, TextStyle, Select,
  Tag, Form, Link, FormLayout, Banner
} from '@shopify/polaris';
import emailTemplates from './emailTemplates';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION = process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class NetTerms1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      payment_options: "Net 15, Net 30, Net 45",
      disable_checkout: true,
      show_delivery_date: true,
      cart_message: "",
      checkout_btn_label: "Net 15/30 Orders Checkout",
      messages: {
        "confirmation": "Please review your order",
        "thank_you": "Your order has been successfully placed. You will now be redirected to our homepage.",
      },
      customers_tag: ["waio-nt"],
      temp_customer_tags_value: "",
      show_payment_mode: true,
      customers_group: "loggedin",
      email_template: {
        "to_owner": {
          "email": this.props.shopData ? this.props.shopData.email : "",
          "subject": `New Net Order Received`,
          "message": `Thank you for placing your order with [shop_name]!
This email is to confirm your recent order.
Date[order_date]
[line_items]
Total order price [total_price]
`,
        }
      },
      owner_email_notify: true,
      customer_email_notify: true,
      order_method: "draft_order",
      edit_id: null,
      page_loaded: false,
      email_template_new: emailTemplates.net_terms,
      owner_template_toggle: false,
      customer_template_toggle: false,
      send_invoice: false,
      price_design: "1",
      validation_error: null,
      saveButtonLoading: false,
      page_url: "pages/net-term-order",
      hide_freeshipping: false,
      separate_payment_mode: false,
      separate_payment_items: [],
      display_shipping_rates: {
        app: true,
        shopify: true,
        beskpoke: false,
      },
      enable_po: false,
      delivery_options: "only_shipping",
      delivery_options_pickup_notes: "",
      enable_notes: false,
      notes: "",
      number_of_days_disabled: 0,
      version: 1,
      net_term_logo: false,
      net_term_title: "Net Payment Checkout",
      is_default_address_checked: true,
      is_billing_same_as_shipping: true, 

      const_data: null, // if any changes occur in this object, save button will be shown
    }
  }

  showToastMessage(msg, error) {
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error || false,
    });
  }

  dissmissToast = () => {
    this.setState({
      showToast: false,
    });
  }

  componentDidMount() {
    if (typeof this.props.netTerms !== "undefined") {

      this.updateNetTerm(this.props.netTerms);

    }
    else {
      this.getNetTerm();
    }
  }

  updateNetTerm(nerTerms) {

    if (nerTerms) {
      var netTerm = nerTerms;
      var { enabled, disable_checkout, show_delivery_date, show_payment_mode, payment_options, owner_email_notify, customer_email_notify, cart_message, checkout_btn_label, messages, customers_tag, customers_group, email_template, order_method } = nerTerms;
      var email_template_new = this.state.email_template_new;
      var price_design = this.state.price_design;
      var send_invoice = this.state.send_invoice;
      try {
        if (netTerm.email_template_new) {
          email_template_new = netTerm.email_template_new;
        }
        if (!email_template_new.owner.to || email_template_new.owner.to === "") {
          if (this.props.shopData && this.props.shopData.email) {
            email_template_new.owner.to = this.props.shopData.email;
          }
        }
      } catch (e) { }
      try {
        if (netTerm.price_design) {
          price_design = netTerm.price_design;
        }
      } catch (e) { }
      try {
        if (netTerm.send_invoice) {
          send_invoice = netTerm.send_invoice;
        }
      } catch (e) { }
      customers_tag = customers_tag.split(',');
      let statesJson = {
        page_url: netTerm.page_url,
        hide_freeshipping: netTerm.hide_freeshipping,
        separate_payment_mode: netTerm.separate_payment_mode,
        separate_payment_items: netTerm.separate_payment_items,
        display_shipping_rates: netTerm.display_shipping_rates,
        enable_po: netTerm.enable_po,
        delivery_options: netTerm.delivery_options,
        delivery_options_pickup_notes: netTerm.delivery_options_pickup_notes,
        enable_notes: netTerm.enable_notes,
        notes: netTerm.notes,
        number_of_days_disabled: netTerm.number_of_days_disabled,
        version: netTerm.version,
        net_term_title: netTerm.net_term_title,
        net_term_logo: netTerm.net_term_logo,
        is_default_address_checked: netTerm.is_default_address_checked,
        is_billing_same_as_shipping: netTerm.is_billing_same_as_shipping,
        enabled, disable_checkout, show_delivery_date, show_payment_mode, payment_options, owner_email_notify, customer_email_notify, cart_message, checkout_btn_label, messages, customers_tag, customers_group, email_template, order_method, email_template_new, send_invoice, price_design
      };


      let const_data = JSON.parse(JSON.stringify(statesJson));

      statesJson.const_data = const_data;
      statesJson.edit_id = netTerm._id;
      this.setState(statesJson);

      this.adjustSeparatePayment(customers_tag, netTerm.separate_payment_items);
    }
    else {
      // net term not found, means its new
      let email_template_new = this.state.email_template_new;
      if (!email_template_new.owner.to || email_template_new.owner.to === "") {
        email_template_new.owner.to = this.props.shopData ? this.props.shopData.email : "";
      }
      if (!email_template_new.owner.from || email_template_new.owner.from === "") {
        email_template_new.owner.from = "noreply@digitalcoo.com";
      }

      this.setState({ email_template_new });
    }
    this.setState({ page_loaded: true });

  }

  getNetTerm() {
    axios.get('/app/get-net-term/' + this.props.shop)
      .then(response => {
        if (response && response.data && response.data.status === 1) {
          if (response.data.netTerms) {
            var netTerm = response.data.netTerms;
            var { enabled, disable_checkout, show_delivery_date, show_payment_mode, payment_options, owner_email_notify, customer_email_notify, cart_message, checkout_btn_label, messages, customers_tag, customers_group, email_template, order_method } = response.data.netTerms;
            var email_template_new = this.state.email_template_new;
            var price_design = this.state.price_design;
            var send_invoice = this.state.send_invoice;
            try {
              if (netTerm.email_template_new) {
                email_template_new = netTerm.email_template_new;
              }
              if (!email_template_new.owner.to || email_template_new.owner.to === "") {
                if (this.props.shopData && this.props.shopData.email) {
                  email_template_new.owner.to = this.props.shopData.email;
                }
              }
            } catch (e) { }
            try {
              if (netTerm.price_design) {
                price_design = netTerm.price_design;
              }
            } catch (e) { }
            try {
              if (netTerm.send_invoice) {
                send_invoice = netTerm.send_invoice;
              }
            } catch (e) { }
            customers_tag = customers_tag.split(',');
            let statesJson = {
              page_url: netTerm.page_url,
              hide_freeshipping: netTerm.hide_freeshipping,
              separate_payment_mode: netTerm.separate_payment_mode,
              separate_payment_items: netTerm.separate_payment_items,
              display_shipping_rates: netTerm.display_shipping_rates,
              enable_po: netTerm.enable_po,
              delivery_options: netTerm.delivery_options,
              delivery_options_pickup_notes: netTerm.delivery_options_pickup_notes,
              enable_notes: netTerm.enable_notes,
              notes: netTerm.notes,
              number_of_days_disabled: netTerm.number_of_days_disabled,
              version: netTerm.version,
              net_term_title: netTerm.net_term_title,
              net_term_logo: netTerm.net_term_logo,
              is_default_address_checked: netTerm.is_default_address_checked,
              is_billing_same_as_shipping: netTerm.is_billing_same_as_shipping,
              enabled, disable_checkout, show_delivery_date, show_payment_mode, payment_options, owner_email_notify, customer_email_notify, cart_message, checkout_btn_label, messages, customers_tag, customers_group, email_template, order_method, email_template_new, send_invoice, price_design
            };

            let const_data = JSON.parse(JSON.stringify(statesJson));

            statesJson.const_data = const_data;
            statesJson.edit_id = netTerm._id;
            this.setState(statesJson);

            this.adjustSeparatePayment(customers_tag, netTerm.separate_payment_items);
          }
          else {
            // net term not found, means its new
            let email_template_new = this.state.email_template_new;
            if (!email_template_new.owner.to || email_template_new.owner.to === "") {
              email_template_new.owner.to = this.props.shopData ? this.props.shopData.email : "";
            }
            if (!email_template_new.owner.from || email_template_new.owner.from === "") {
              email_template_new.owner.from = "noreply@digitalcoo.com";
            }

            this.setState({ email_template_new });
          }
        }
        this.setState({ page_loaded: true });
      })
      .catch(error => {
        this.showToastMessage(error, true);
        this.setState({ page_loaded: true });
      });
  }

  IsValid() {
    var valid = true;
    var {
      enabled,
      customers_group,
      customers_tag
    } = this.state;
    if (enabled) {
      try {
        if ("tag_based" === customers_group && 1 > customers_tag.length) valid = false
      } catch (e) { }
    }
    return valid;
  }

  validation() {
    let error_field = null; // Will help show errors on specefic fields
    let {
      enabled,
      customers_group,
      customers_tag
    } = this.state;

    // If net term is enabled, validate things
    if (enabled) {
      // Validate Customer - Priority => Least
      if ("tag_based" === customers_group && 1 > customers_tag.length) {
        error_field = {
          state: "temp_customer_tags_value",
          id: "customer-tag-input",
          msg: "Tags are required"
        };
      }
    }


    return error_field;

  }

  saveData() {
    if(!this.hasFeatures('net_term')) {
      this.showToastMessage("Please subscribe to this addon to use it", true);
      return;
    }
    this.setState({ saveButtonLoading: true, validation_error: null });

    // ==============
    //  Validating...
    // ==============

    const error = this.validation();
    if (error) {
      // Some error found, update state 
      this.setState({
        validation_error: error,
        saveButtonLoading: false
      });
      // Scroll to that elem
      const elem = document.getElementById(error.id);
      if (elem) this.scrollToTop(elem);
      return;
    }

    // ==============
    //  Saving Data...
    // ==============

    let customer_tags = this.state.customers_tag.join(',');
    customer_tags && (customer_tags = customer_tags.trim());
    if (customer_tags.indexOf(',') > -1) {
      // Remove spaces between tags
      customer_tags = customer_tags.split(',').map(x => x.trim()).join(',');

    }
    var data = {
      shop: this.props.shop,
      enabled: this.state.enabled,
      disable_checkout: this.state.disable_checkout,
      show_delivery_date: this.state.show_delivery_date,
      show_payment_mode: this.state.show_payment_mode,
      payment_options: this.state.payment_options,
      owner_email_notify: this.state.owner_email_notify,
      customer_email_notify: this.state.customer_email_notify,
      cart_message: this.state.cart_message,
      checkout_btn_label: this.state.checkout_btn_label,
      messages: this.state.messages,
      customers_tag: customer_tags,
      customers_group: this.state.customers_group,
      email_template: this.state.email_template,
      email_template_new: this.state.email_template_new,
      send_invoice: this.state.send_invoice,
      price_design: this.state.price_design,
      order_method: this.state.order_method,
      page_url: this.state.page_url,
      hide_freeshipping: this.state.hide_freeshipping,
      separate_payment_mode: this.state.separate_payment_mode && this.state.separate_payment_items.length > 1 && this.state.customers_group === "tag_based",
      separate_payment_items: this.state.separate_payment_items,
      display_shipping_rates: this.state.display_shipping_rates,
      enable_po: this.state.enable_po,
      delivery_options: this.state.delivery_options,
      delivery_options_pickup_notes: this.state.delivery_options_pickup_notes,
      enable_notes: this.state.enable_notes,
      notes: this.state.notes,
      number_of_days_disabled: this.state.number_of_days_disabled,
      version: this.state.version,
      net_term_logo: this.state.net_term_logo,
      net_term_title: this.state.net_term_title,
      is_default_address_checked: this.state.is_default_address_checked,
      is_billing_same_as_shipping: this.state.is_billing_same_as_shipping
    };
    if (this.state.edit_id) {
      axios.put('/app/update-net-term/' + this.state.edit_id, data)
        .then(response => {
          if (response && response.data) {
            if (response.data.status === 1) {
              this.showToastMessage(response.data.message);
            }
            else {
              this.showToastMessage(response.data.message, true);
            }
          }
          else {
            this.showToastMessage("An error occure", true);
          }

          this.setState({ saveButtonLoading: false });

          this.getNetTerm();
        })
        .catch(error => {
          this.showToastMessage(error, true);
          this.setState({ saveButtonLoading: false });
        });
    }
    else {
      axios.post('/app/save-net-term/', data)
        .then(response => {
          if (response && response.data) {
            if (response.data.status === 1) {
              this.showToastMessage(response.data.message);
            }
            else {
              this.showToastMessage(response.data.message, true);
            }
          }
          else {
            this.showToastMessage("An error occure", true);
          }

          this.setState({ saveButtonLoading: false });
          this.getNetTerm();
        })
        .catch(error => {
          this.showToastMessage(error, true);
          this.setState({ saveButtonLoading: false });
        });
    }
  }

  /* Detect Changes */
  detectChanges() {
    let constData = this.state.const_data;

    /* this will detect if anything changes on page */

    /**
    | const_data: constant data, that should not be change
    | compare_data: data to compare, is not match, data is changed
    */
    const _comparechange = function (const_data, compare_data) {

      /* Function that will compare both objects */
      let deepCompare = () => {
        var i, l, leftChain, rightChain;

        function compare2Objects(x, y) {
          var p;

          // remember that NaN === NaN returns false
          // and isNaN(undefined) returns true
          if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
          }

          // Compare primitives and functions.     
          // Check if both arguments link to the same object.
          // Especially useful on the step where we compare prototypes
          if (x === y) {
            return true;
          }

          // Works in case when functions are created in constructor.
          // Comparing dates is a common scenario. Another built-ins?
          // We can even handle functions passed across iframes
          if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
          }

          // At last checking prototypes as good as we can
          if (!(x instanceof Object && y instanceof Object)) {
            return false;
          }

          if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
          }

          if (x.constructor !== y.constructor) {
            return false;
          }

          if (x.prototype !== y.prototype) {
            return false;
          }

          // Check for infinitive linking loops
          if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
          }

          // Quick checking of one object being a subset of another.
          // todo: cache the structure of arguments[0] for performance
          for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
              return false;
            } else if (typeof y[p] !== typeof x[p]) {
              return false;
            }
          }

          for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
              return false;
            } else if (typeof y[p] !== typeof x[p]) {
              return false;
            }

            switch (typeof (x[p])) {
              case 'object':
              case 'function':

                leftChain.push(x);
                rightChain.push(y);

                if (!compare2Objects(x[p], y[p])) {
                  return false;
                }

                leftChain.pop();
                rightChain.pop();
                break;

              default:
                if (x[p] !== y[p]) {
                  return false;
                }
                break;
            }
          }

          return true;
        }

        if (arguments.length <= 1) {
          //return true; //Die silently? Don't know how to handle such case, please help...
          // eslint-disable-next-line
          throw "Need two or more arguments to compare";
        }

        for (i = 1, l = arguments.length; i < l; i++) {

          leftChain = []; //Todo: this can be cached
          rightChain = [];

          if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
          }
        }

        return true;
      }

      return !deepCompare(const_data, compare_data);
    };

    /* Match whole data */
    const compareData = {
      enabled: this.state.enabled,
      disable_checkout: this.state.disable_checkout,
      show_delivery_date: this.state.show_delivery_date,
      show_payment_mode: this.state.show_payment_mode,
      payment_options: this.state.payment_options,
      owner_email_notify: this.state.owner_email_notify,
      customer_email_notify: this.state.customer_email_notify,
      cart_message: this.state.cart_message,
      checkout_btn_label: this.state.checkout_btn_label,
      messages: this.state.messages,
      customers_tag: this.state.customers_tag,
      customers_group: this.state.customers_group,
      email_template: this.state.email_template,
      email_template_new: this.state.email_template_new,
      send_invoice: this.state.send_invoice,
      price_design: this.state.price_design,
      order_method: this.state.order_method,
      page_url: this.state.page_url,
      hide_freeshipping: this.state.hide_freeshipping,
      separate_payment_mode: this.state.separate_payment_mode,
      separate_payment_items: this.state.separate_payment_items,
      display_shipping_rates: this.state.display_shipping_rates,
      enable_po: this.state.enable_po,
      delivery_options: this.state.delivery_options,
      delivery_options_pickup_notes: this.state.delivery_options_pickup_notes,
      enable_notes: this.state.enable_notes,
      notes: this.state.notes,
      number_of_days_disabled: this.state.number_of_days_disabled,
      version: this.state.version,
      net_term_logo: this.state.net_term_logo,
      net_term_title: this.state.net_term_title,
      is_default_address_checked: this.state.is_default_address_checked,
      is_billing_same_as_shipping: this.state.is_billing_same_as_shipping
    };
    return _comparechange(constData, compareData);
  }

  addCustomerTags(tags_key, temp_tags_key) {
    /* Fetch tags from state */
    var temp_customer_tags_value = this.state[temp_tags_key];
    try {
      temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
    } catch (e) { }
    if (temp_customer_tags_value.trim() === "") return;

    /* Check if tags are not already exists */
    var customer_tags = this.state[tags_key];
    if (customer_tags.some(tag => tag.toLowerCase() === temp_customer_tags_value.toLowerCase())) {
      this.showToastMessage("Tag already exists", true);
      return false;
    }

    /* Update tags */
    customer_tags.push(temp_customer_tags_value);
    this.setState({ [tags_key]: customer_tags, [temp_tags_key]: "" });

    this.adjustSeparatePayment();

  }

  removeCustomerTag(tag, i, el) {
    var customer_tag = this.state.customers_tag;
    customer_tag.splice([i], 1);
    this.setState({ customers_tag: customer_tag });

    this.adjustSeparatePayment();

  }

  // remap options to match tags
  adjustSeparatePayment(tags = null, items = null) {
    const customer_tag = tags ? tags : this.state.customers_tag;
    if (customer_tag.length > 1) {
      const separate_payment_items = items ? items : this.state.separate_payment_items;
      const modes = customer_tag.map(x => {

        const itemIndex = separate_payment_items.findIndex(y => y.tag.toLowerCase() === x.toLowerCase());
        let tag = x;
        let option_text = this.state.payment_options;
        if (itemIndex > -1) {
          // already found? update it
          tag = separate_payment_items[itemIndex].tag;
          option_text = separate_payment_items[itemIndex].option_text;

        }

        return { tag: tag, option_text: option_text };
      });

      this.setState({
        separate_payment_items: modes
      });
    }
    else {

      this.setState({
        separate_payment_items: []
      });
    }
  }

  hasFeatures(value){
    var hasF = false;
    if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
      hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
    }
    return hasF;
  }

  render() {
    const { price_design, send_invoice, customer_template_toggle, owner_template_toggle, email_template_new, enabled, payment_options, disable_checkout, show_delivery_date, messages, checkout_btn_label, cart_message, show_payment_mode, customers_group, owner_email_notify, customer_email_notify, order_method, net_term_logo, delivery_options } = this.state;
    const changes_detected = this.detectChanges();

    const section1 = <Card title="Net Terms" primaryAction={{ content: "Save", onAction: () => this.saveData() }}>
      <Card.Section>
        <Stack vertical spacing="tight">
          <Checkbox
            label="Enable Net Terms Feature "
            checked={enabled}
            onChange={(enabled) => this.setState({ enabled })}
          />
          {
            enabled && <TextField
              label="Display on page"
              autoComplete="off"
              value={this.state.page_url}
              helpText="For example: pages/net-term-order"
              prefix={`https://${this.props.shop} /`}
              onChange={(v) => { this.setState({ page_url: v }) }}
            />
          }
        </Stack>
      </Card.Section>
      {
        enabled && <Card.Section>
          <Stack vertical spacing="tight">
            <Heading>Offer net terms checkout options to</Heading>
            <Stack vertical spacing="extraTight">
              <RadioButton
                label="All logged in customers"
                helpText="Offer the net terms checkout option to all login customers regardless the customer tags."
                checked={"loggedin" === customers_group}
                id="loggedin"
                name="customers_group"
                onChange={(v, id) => {
                  this.setState({ customers_group: id })
                }}
              />
              <RadioButton
                label="Specific tagged customers"
                helpText="Only offer the net terms checkout option to those customer accounts tagged with the tag you specify here."
                checked={"tag_based" === customers_group}
                id="tag_based"
                name="customers_group"
                onChange={(v, id) => {
                  this.setState({ customers_group: id })
                }}
              />
              {
                "tag_based" === customers_group && <div style={{ marginLeft: "3rem" }}>
                  <Stack vertical>
                    <Stack>
                      <Stack.Item>
                        <Form onSubmit={() => {
                          this.addCustomerTags("customers_tag", "temp_customer_tags_value");
                        }}>
                          <TextField
                            label="Customer tags"
                            id="customer-tag-input"
                            labelHidden
                            value={this.state.temp_customer_tags_value}
                            placeholder="Enter tags"
                            onChange={(v) => {
                              this.setState({ temp_customer_tags_value: v });
                            }}
                            connectedRight={
                              <Button
                                primary
                                onClick={() => {
                                  this.addCustomerTags("customers_tag", "temp_customer_tags_value");
                                }}
                              >Add</Button>
                            }
                            error={this.state.customers_tag.length === 0 ? "Tags are required" : this.state.validation_error && this.state.validation_error.state === "temp_customer_tags_value" ? this.state.validation_error.msg : false}
                          />
                        </Form>
                      </Stack.Item>
                      <Stack.Item fill><div></div></Stack.Item>
                    </Stack>


                    {this.state.customers_tag.length > 0 && <Stack spacing="tight">
                      {this.state.customers_tag.map((tag, i) => {
                        return <span key={i}>
                          <Tag onRemove={this.removeCustomerTag.bind(this, tag, i)}>{tag}</Tag>
                        </span>
                      })}
                    </Stack>
                    }
                  </Stack>
                </div>
              }
            </Stack>
          </Stack>
        </Card.Section>
      }
    </Card>;

    const section2 = <Card title="General Settings">
      <Card.Section>
        <Stack vertical spacing="tight">
          <Checkbox
            label="Remove default Shopify checkout button when Net Terms feature is enabled."
            checked={disable_checkout}
            onChange={(disable_checkout) => this.setState({ disable_checkout })}
          />
          <Checkbox
            label="Enable PO field on Net Payment checkout."
            checked={this.state.enable_po}
            onChange={(v) => this.setState({ enable_po: v })}
          />
          <Checkbox
            label="Display instructions on Net Term Checkout page."
            checked={this.state.enable_notes}
            onChange={(v) => this.setState({ enable_notes: v })}
          />
          {
            enabled && this.state.enable_notes && <div style={{ marginLeft: "3rem" }}>
              <TextField
                label="Notes"
                labelHidden
                autoComplete="off"
                multiline={3}
                value={this.state.notes}
                helpText="Customers will see these instructions on Net Term checkout page."
                onChange={(v) => { this.setState({ notes: v }) }}
              />
            </div>
          }
        </Stack>
      </Card.Section>
    </Card>

    const section3 = <Card title="Payment options">
      <Card.Section>
        <Stack vertical>
          <Checkbox
            label="Show payment mode on net term order form"
            checked={show_payment_mode}
            onChange={(show_payment_mode) => { this.setState({ show_payment_mode }) }}
          />

          {show_payment_mode && <>


            <Stack vertical>
              <Checkbox
                disabled={customers_group !== "tag_based" || this.state.customers_tag.length === 0 || this.state.separate_payment_items.length <= 1}
                label={
                  <>
                    <span>Show different payment modes for tag based customers</span>
                    &nbsp;&nbsp;<Link url="https://support.digitalcoo.com/hc/en-us/articles/4401801289108" external>Learn More</Link>
                  </>
                }
                helpText="Will work only when Tag Based Customer is selected"
                checked={this.state.separate_payment_mode && customers_group === "tag_based" && this.state.separate_payment_items.length > 1}
                onChange={(separate_payment_mode) => { this.setState({ separate_payment_mode }) }}
              />

              {this.state.separate_payment_mode && this.state.separate_payment_items.length > 1 && customers_group === "tag_based" ?
                <Stack vertical spacing="extraTight">
                  {this.state.separate_payment_items.map((item, index) => {

                    return (<Stack key={index}>
                      <div style={{ maxWidth: "14rem" }}>
                        <TextField
                          autoComplete="off"
                          label="Customer Tag"
                          labelHidden={index !== 0}
                          value={item.tag}
                          id={"separate-payment-mode-tag" + index}
                          type="text"
                          disabled={true}
                        />
                      </div>

                      <div style={{ width: "35rem" }}>
                        <TextField
                          autoComplete="off"
                          label="Payment Options"
                          labelHidden={index !== 0}
                          value={item.option_text}
                          id={"separate-payment-mode-text" + index}
                          type="text"
                          onChange={(v) => {
                            const items = this.state.separate_payment_items;
                            items[index].option_text = v;
                            this.setState({ separate_payment_items: items, validation_error: null });
                          }}
                        />
                      </div>

                    </Stack>);
                  })}

                </Stack>
                :
                <TextField
                  value={payment_options}
                  onChange={(v) => { this.setState({ payment_options: v }) }}
                />
              }
            </Stack>


          </>
          }
        </Stack>
      </Card.Section>
    </Card>;

    const section4 = <Card title="Order method">
      <Card.Section>
        <Stack vertical spacing="tight">
          <RadioButton
            label="Draft order"
            helpText="Order is created as a draft in the Shopify admin (Admin > Orders > Draft)."
            name="order_method"
            checked={"draft_order" === order_method}
            id="draft_order"
            onChange={(v, id) => { this.setState({ order_method: id }) }}
          />
          <RadioButton
            label="Final order"
            helpText="Order is created and marked as unpaid in the Shopify admin (Admin > Orders)."
            name="order_method"
            checked={"final_order" === order_method}
            id="final_order"
            onChange={(v, id) => { this.setState({ order_method: id }) }}
          />
        </Stack>
      </Card.Section>
    </Card>;

    const section5 = <Card title="Notifications">
      <Card.Section title="Alerts">
        <Stack vertical>
          <TextField
            label="Confirmation message"
            value={messages.confirmation}
            onChange={(v) => {
              var messages = this.state.messages;
              messages.confirmation = v;
              this.setState({ messages: messages })
            }}
          />
          <TextField
            label="Thank you message"
            value={messages.thank_you}
            onChange={(v) => {
              var messages = this.state.messages;
              messages.thank_you = v;
              this.setState({ messages: messages })
            }}
          />
        </Stack>
      </Card.Section>
      <Card.Section Cart labels>
        <Stack vertical spacing="tight">
          <Heading>Cart Labels</Heading>
          <Stack vertical spacing="extraTight">
            <TextField
              label="Cart message"
              value={cart_message}
              onChange={(v) => { this.setState({ cart_message: v }) }}
            />
            <TextField
              label="Checkout button label"
              value={checkout_btn_label}
              onChange={(v) => { this.setState({ checkout_btn_label: v }) }}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Card>;

    const section6 = <Card title="Email templates" sectioned>
      <Stack vertical spacing="tight">
        <div>
          <Stack vertical spacing="extraTight">
            <div>
              <Stack>
                <Stack.Item fill>
                  <Heading>Store owner email configurations</Heading>
                  <div>
                    <TextStyle variation="subdued">Email from, to, subject and body</TextStyle>
                  </div>
                </Stack.Item>
                <Stack.Item>
                  <Button size="slim"
                    onClick={() => { this.setState({ owner_template_toggle: !owner_template_toggle }) }}
                  >{owner_template_toggle ? "Close" : "Open"}</Button>
                </Stack.Item>
              </Stack>
            </div>
          </Stack>
        </div>
        <div>
          <Collapsible
            open={owner_template_toggle}
            id="basic-collapsible"
            transition={{ duration: '150ms', timingFunction: 'ease' }}
          >
            <Stack vertical spacing="tight">
              <Checkbox
                helpText="When this is checked, customer will receive an invoice"
                label="Send invoice automatically"
                checked={send_invoice}
                id="send_invoice"
                onChange={(v) => { this.setState({ send_invoice: v }) }}
              />
              <Checkbox
                label="Receive an internal Email once customer place a net term order"
                checked={owner_email_notify}
                id="owner_email_notify"
                onChange={(v) => { this.setState({ owner_email_notify: v }) }}
              />
              {
                owner_email_notify && <div>
                  <Stack>
                    <Stack.Item fill>
                      <Select
                        label="Price design"
                        value={price_design}
                        options={[
                          { value: "1", label: "Template 1" },
                          { value: "2", label: "Template 2" },
                          { value: "3", label: "Template 3" }
                        ]}
                        onChange={(v) => { this.setState({ price_design: v }) }}
                      ></Select>
                    </Stack.Item>
                    <Stack.Item>
                      <div>
                        {
                          price_design === "1" && <img src="/images/temp1.png" alt="sd"></img>
                        }
                        {
                          price_design === "2" && <img src="/images/temp2.png" alt="sdd"></img>
                        }
                        {
                          price_design === "3" && <img src="/images/temp3.png" alt="sdds"></img>
                        }
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>
              }
              {
                owner_email_notify && <div>
                  <Stack vertical>
                    <Stack distribution="fill">
                      <Stack.Item>
                        <TextField
                          label="Send email From"
                          helpText="Your customers will receive order confirmation emails from this email address"
                          value={email_template_new.owner.from}
                          onChange={(v) => {
                            var email_template_new = this.state.email_template_new;
                            email_template_new.owner.from = v;
                            this.setState({ email_template_new });
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          label="Send email to"
                          value={email_template_new.owner.to}
                          helpText="You’ll receive a notification at this email address when a customer places a Net Terms order"
                          onChange={(v) => {
                            var email_template_new = this.state.email_template_new;
                            email_template_new.owner.to = v;
                            this.setState({ email_template_new });
                          }}
                        />
                      </Stack.Item>
                    </Stack>
                    <TextField
                      label="Subject"
                      value={email_template_new.owner.subject}
                      onChange={(v) => {
                        var email_template_new = this.state.email_template_new;
                        email_template_new.owner.subject = v;
                        this.setState({ email_template_new });
                      }}
                    />
                    <TextField
                      label="Content"
                      helpText="Use these values. [[shop_name]], [[order_date]], [[line_items]], [[total_price]], [[order_number]], [[customer_first_name]], [[customer_last_name]], [[customer_full_name]], [[store_contact_email]], [[line_items]], [[entire_order_subtotal]], [[entire_order_shipping_cost]], [[order_total_price]], [[you_saved_price]], [[entire_order_discount]], [[shipping_method]], [[shipping_address]], [[order_notes]], [[billing_address]]"
                      multiline={3}
                      value={email_template_new.owner.html}
                      onChange={(v) => {
                        var email_template_new = this.state.email_template_new;
                        email_template_new.owner.html = v;
                        this.setState({ email_template_new });
                      }}
                    />
                  </Stack>
                </div>
              }
            </Stack>
          </Collapsible>
        </div>
        <div>
          <Stack vertical spacing="extraTight">
            <div>
              <Stack>
                <Stack.Item fill>
                  <Heading>Customer email configurations</Heading>
                  <div>
                    <TextStyle variation="subdued">subject and body</TextStyle>
                  </div>
                </Stack.Item>
                <Stack.Item>
                  <Button size="slim"
                    onClick={() => { this.setState({ customer_template_toggle: !customer_template_toggle }) }}
                  >{customer_template_toggle ? "Close" : "Open"}</Button>
                </Stack.Item>
              </Stack>
            </div>
          </Stack>
        </div>
        <div>
          <Collapsible
            open={customer_template_toggle}
            id="basic-collapsible"
            transition={{ duration: '150ms', timingFunction: 'ease' }}
          >
            <div>
              <Stack vertical spacing="tight">
                <Checkbox
                  label="Send customer order confirmation Email"
                  checked={customer_email_notify}
                  id="customer_email_notify"
                  onChange={(v) => { this.setState({ customer_email_notify: v }) }}
                />
                {
                  customer_email_notify && <div>
                    <Stack vertical>
                      <TextField
                        label="Subject"
                        value={email_template_new.customer.subject}
                        onChange={(v) => {
                          var email_template_new = this.state.email_template_new;
                          email_template_new.customer.subject = v;
                          this.setState({ email_template_new });
                        }}
                      />
                      <TextField
                        label="Content"
                        helpText="Use these values. [[shop_name]], [[order_date]], [[line_items]], [[total_price]], [[order_number]], [[customer_first_name]], [[customer_last_name]], [[customer_full_name]], [[store_contact_email]], [[line_items]], [[entire_order_subtotal]], [[entire_order_shipping_cost]], [[order_total_price]], [[you_saved_price]], [[entire_order_discount]], [[shipping_method]], [[shipping_address]], [[order_notes]], [[billing_address]]"
                        multiline={3}
                        value={email_template_new.customer.html}
                        onChange={(v) => {
                          var email_template_new = this.state.email_template_new;
                          email_template_new.customer.html = v;
                          this.setState({ email_template_new });
                        }}
                      />
                    </Stack>
                  </div>
                }
              </Stack>
            </div>
          </Collapsible>
        </div>
      </Stack>
    </Card>;


    const section8 = <Card title="Delivery options">
      <Card.Section>
        <Stack vertical spacing="tight">
          <RadioButton
            label="Only shipping"
            checked={"only_shipping" === this.state.delivery_options}
            name="delivery_options"
            onChange={() => {
              this.setState({ delivery_options: "only_shipping" })
            }}
          />
          <RadioButton
            label="Shipping and Pickup [Default: Shipping]"
            checked={"pickup_and_shipping_dshipping" === this.state.delivery_options}
            name="delivery_options"
            onChange={() => {
              this.setState({ delivery_options: "pickup_and_shipping_dshipping" })
            }}
          />
          {
            this.state.delivery_options === "pickup_and_shipping_dshipping" && <div style={{ marginLeft: "3rem" }}>
              <TextField
                label="Pickup Locations"
                labelHidden
                autoComplete="off"
                multiline={3}
                value={this.state.delivery_options_pickup_notes}
                helpText="Pickup locations"
                onChange={(v) => { this.setState({ delivery_options_pickup_notes: v }) }}
              />
            </div>
          }
          <RadioButton
            label="Pickup & Shipping [Default: Pickup]"
            checked={"pickup_and_shipping_dpickep" === this.state.delivery_options}
            name="delivery_options"
            onChange={() => {
              this.setState({ delivery_options: "pickup_and_shipping_dpickep" })
            }}
          />
          {
            this.state.delivery_options === "pickup_and_shipping_dpickep" && <div style={{ marginLeft: "3rem" }}>
              <TextField
                label="Pickup Locations"
                labelHidden
                autoComplete="off"
                multiline={3}
                value={this.state.delivery_options_pickup_notes}
                helpText="Pickup locations"
                onChange={(v) => { this.setState({ delivery_options_pickup_notes: v }) }}
              />
            </div>
          }
          <RadioButton
            label="Only pickup"
            checked={"only_pickup" === this.state.delivery_options}
            name="delivery_options"
            onChange={() => {
              this.setState({ delivery_options: "only_pickup" })
            }}
          />
          {
            this.state.delivery_options === "only_pickup" && <div style={{ marginLeft: "3rem" }}>
              <TextField
                label="Pickup Locations"
                labelHidden
                autoComplete="off"
                multiline={3}
                value={this.state.delivery_options_pickup_notes}
                helpText="Pickup locations"
                onChange={(v) => { this.setState({ delivery_options_pickup_notes: v }) }}
              />
            </div>
          }
          <RadioButton
            label="None"
            checked={"none" === this.state.delivery_options}
            name="delivery_options"
            onChange={() => {
              this.setState({ delivery_options: "none" })
            }}
          />
        </Stack>
      </Card.Section>
      {
        (delivery_options === "only_shipping" || delivery_options === "pickup_and_shipping_dpickep" || delivery_options === "pickup_and_shipping_dshipping") &&
        <Card.Section>
          <Stack vertical spacing="baseTight">
            <Heading>Display Shipping rates</Heading>
            <Stack vertical spacing="tight">
              <Checkbox
                label="Show shipping rates based on Wholesale All In One app"
                checked={this.state.display_shipping_rates && this.state.display_shipping_rates.app}
                onChange={(enabled) => {
                  let rates = this.state.display_shipping_rates;
                  rates.app = enabled;

                  this.setState({ display_shipping_rates: rates });
                }}
              />
              <Checkbox
                label="Show Shopify calculated shipping rates"
                checked={this.state.display_shipping_rates && this.state.display_shipping_rates.shopify}
                onChange={(enabled) => {
                  let rates = this.state.display_shipping_rates;
                  rates.shopify = enabled;

                  this.setState({ display_shipping_rates: rates });
                }}
              />
            </Stack>
          </Stack>
        </Card.Section>
      }
      {
        (delivery_options === "only_shipping" || delivery_options === "pickup_and_shipping_dpickep" || delivery_options === "pickup_and_shipping_dshipping") &&
        <Card.Section>
          <Stack vertical spacing="tight">
            <Heading>Additional Settings</Heading>
            <Stack vertical spacing="extraTight">
              <Checkbox
                label="Auto load default customer address on Net Term checkout page"
                checked={this.state.is_default_address_checked}
                onChange={(is_default_address_checked) => {
                  this.setState({ is_default_address_checked: is_default_address_checked });
                }}
              />
              <Checkbox
                label="Enable default option 'Billing is same as shipping'"
                checked={this.state.is_billing_same_as_shipping}
                onChange={(v) => this.setState({ is_billing_same_as_shipping: v })}
              />
              <Checkbox
                label="Hide free shipping option from Net Payment checkout"
                checked={this.state.hide_freeshipping}
                onChange={(v) => this.setState({ hide_freeshipping: v })}
              />
              <Checkbox
                label="Show a delivery date option on the net order form"
                checked={show_delivery_date}
                onChange={(show_delivery_date) => this.setState({ show_delivery_date })}
              />
              {
                show_delivery_date && <div>
                  <FormLayout>
                    <FormLayout.Group>
                      <div className="list-bordered-item" style={{ marginLeft: "30px" }}>
                        <TextField
                          label="Disable future dates after today (Enter number of days)"
                          autoComplete="off"
                          value={this.state.number_of_days_disabled}
                          onChange={(v) => {
                            this.setState({ number_of_days_disabled: v })
                          }}
                          onBlur={() => {
                            let v = parseInt(this.state.number_of_days_disabled) || 0;
                            if (v < 0) {
                              v = 0;
                            }
                            this.setState({ number_of_days_disabled: v.toString() })
                          }}
                        />
                      </div>
                      <div></div>
                    </FormLayout.Group>
                  </FormLayout>
                </div>
              }
            </Stack>
          </Stack>
        </Card.Section>
      }
    </Card>;

    const section9 = <Card title="Net payment page title and logo">
      <Card.Section>
        <Stack vertical spacing="tight">
          <TextField
            disabled={this.state.net_term_logo}
            label="Enter title for Net Payment checkout page"
            value={this.state.net_term_title}
            onChange={(v) => { this.setState({ net_term_title: v }) }}
          />
          <Checkbox
            label="Display logo on Net Payment checkout"
            checked={this.state.net_term_logo}
            onChange={(net_term_logo) => { this.setState({ net_term_logo }) }}
          />
          {
            net_term_logo &&
            <TextStyle variation="subdued">The logo will only appear if it has been added to the theme customization, otherwise the page title will appear.</TextStyle>
          }
        </Stack>
      </Card.Section>
    </Card>

    var toast = this.state.showToast ? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ) : (null);


    if (this.state.page_loaded) {
      return (
        <div>
          <PageTitleBar
            title="Net Payment Addon"
            breadcrumbs={[{ content: 'Dashboard', url: BUILD_VERSION_EXTENSION + '/dashboard', target: "APP" }]}
            primaryAction={{ content: "Save", onAction: () => this.saveData(), disabled: this.state.saveButtonLoading || !changes_detected, loading: this.state.saveButtonLoading || !this.hasFeatures('net_term') }}
            secondaryActions={[{ content: "Back", url: BUILD_VERSION_EXTENSION + "/dashboard", target: "APP" }]}
          />
          {
            !this.hasFeatures("net_term") &&
            <div style={{marginBottom: '1rem'}}>
              <Banner
                title="Activation Required"
                status="critical"
              >
                <p>To use the Net Term addon, please activate it in the Billing section first. <Link external url='https://support.digitalcoo.com/hc/en-us/articles/360045551651'>[View details]</Link> | <Link url={BUILD_VERSION_EXTENSION+"/update-plans"}>[Go to Billing section]</Link></p>
              </Banner>
            </div>
          }
          {toast}
          {section1}
          {enabled && section9}
          {enabled && section2}
          {enabled && section3}
          {enabled && section4}
          {enabled && section8}
          {enabled && section5}
          {enabled && section6}
        </div>
      );
    }
    else {
      return (<SkeletonPageComp></SkeletonPageComp>);
    }
  }
}