import React, { Component } from 'react';
import axios from '../Axios';
import { Stack, Layout, Card, TextField, PageActions, Banner, Spinner,TextStyle } from '@shopify/polaris';
import ToastMessage from '../Common/ToastMessage';
import store from 'store2';

class EmailTemplate extends Component{
  constructor(props){
    super(props);
    this.state = {
      shop: this.props.shop || "",
      uid: this.props.match.params.uid || null,
      template_id: this.props.match.params.id || "1",
      _id: null,
      email_to: store("owner_email") || "",
      email_subject: "",
      email_body: "",
      page_loaded: this.props.match.params.uid ? false: true,
      error_message: "",
      show_error: false,
      showToast: false,
      toastMsg: "",
      toastError: false,
    }
  }

  componentDidMount(){
    if(this.props.match.params.uid){
      axios.get("/app/v2/ws-form-template?uid="+this.props.match.params.uid+"&template_id="+this.props.match.params.id+"&shop="+this.state.shop).then( response => {
        if(response && response.data){
          this.setState({
            page_loaded: true,
            email_to: response.data.to,
            email_subject: response.data.subject,
            email_body: response.data.body,
            _id: response.data._id
          });
        }
      }).catch(e => {
        this.setState({
          page_loaded: true,
          show_error: true,
          error_message: "Server error. Close this modal and re-open"
        });
      })
    }
  }

  updateTemplate(){
    var data = {
      "to": this.state.email_to,
      "subject": this.state.email_subject,
      "body": this.state.email_body
    };
    axios.put("/app/v2/ws-form-template/"+this.props.match.params.uid+"/"+this.state._id, data).then( response => {
      if(response && response.data && response.data.status === 1){
        this.setState({
          showToast: true,
          toastMsg: response.data.message,
          toastError: false
        });
        
        // Close the modal [sends postMessage to parent modal]
        try{
          window.parent['app-iframe'].postMessage({closemodal:this.props.match.params.id, email_to: this.state.email_to});
        } catch(e) {}
      }
      else if(response.data.message){
        this.setState({
          showToast: true,
          toastMsg: response.data.message,
          toastError: true
        });
      }
      else{
        this.setState({
          showToast: true,
          toastMsg: "Server error",
          toastError: true
        });
      }
    }).catch(e => {
      this.setState({
        page_loaded: true,
        show_error: true,
        error_message: "Server error. Close this modal and re-open",
        showToast: true,
        toastMsg: "Server error",
        toastError: true
      });
    })
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  render(){
    // eslint-disable-next-line
    const email_regex = new RegExp(/^(\s*[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\s*,\s*)*(\s*[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\s*)$/g);
    var toast = this.state.showToast? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ): (null);

    if(this.state.page_loaded){
      return(
        <div>
          {toast}
          <Layout>
            <Layout.Section>
              <div className="ws-sticky-container">
                <PageActions
                  primaryAction={{
                    content: 'Update template',
                    onAction: () => {
                      if(this.state.template_id === '1' || this.state.template_id === '5') {
                        if(this.state.email_to.length > 0){
                          // eslint-disable-next-line
                          if(RegExp(/^(\s*[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\s*,\s*)*(\s*[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\s*)$/g).test(this.state.email_to)) {
                            this.updateTemplate();
                          }
                          else this.showToastMessage("Please enter valid email address(es)", true);
                        }
                        else this.updateTemplate();
                      }
                      else this.updateTemplate();
                    }
                  }}
                ></PageActions>
              </div>
            </Layout.Section>
            <Layout.Section>
            {
              this.state.show_error ?
              <div>
                <Banner status="critical">
                  <p>{this.state.error_message}</p>
                </Banner>
              </div>
              :<div style={{marginTop: "3.1rem"}}>
                <Card>
                <Card.Section>
                    <Stack vertical>
                      {
                        (this.state.template_id === "1" || this.state.template_id === "5") && 
                        <Stack.Item>
                          <TextField
                            label="Send email to"
                            error={(this.state.email_to && typeof this.state.email_to !== "undefined") ? this.state.email_to.trim().length < 1 ? "Please enter valid email address(es)" : !email_regex.test(this.state.email_to) ? "Please enter valid email address(es)" : false : false}
                            value={this.state.email_to}
                            helpText="You can add multiple recipient emails separated by commas (one@email.com,two@email.com)"
                            onChange={(email_to) => { this.setState({email_to})}}
                            maxLength={255}
                          />
                        </Stack.Item>
                      }
                      <Stack.Item>
                        <TextField
                          label="Subject"
                          value={this.state.email_subject}
                          onChange={(email_subject) => { this.setState({email_subject})}}
                          maxLength={255}
                          showCharacterCount
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          multiline={15}
                          label="Body"
                          value={this.state.email_body}
                          onChange={(email_body) => { this.setState({email_body})}}
                          maxLength={50000}
                          showCharacterCount
                        />
                      </Stack.Item>
                      <TextStyle variation='subdued'>Use these values. [[store_name]], [[store_link]], [[customer_email]], [[store_customer_email]]{(this.state.template_id === "2" || this.state.template_id === "4" || this.state.template_id === "6") ? ', [[customer_first_name]], [[customer_last_name]], [[customer_phone]], [[customer_country]], [[customer_address1]], [[customer_address2]], [[customer_company]]':''}{this.state.template_id === '4' ? ', [[reason_for_decline]]' : ''}{(this.state.template_id === '1' || this.state.template_id === '5') ? ', [[approve_customer_link]], [[customer_details]]' : ''}{(this.state.template_id === '2' || this.state.template_id === '3' ||this.state.template_id === '6') ? ', [[activate_account_link]]' : ''}</TextStyle>
                    </Stack>
                  </Card.Section>
                </Card>
              </div>
            }
            </Layout.Section>
          </Layout>
        </div>
      );
    }
    else{
      return(
        <div className="snipper-container">
          <center><Spinner color="teal" size="large" /></center>
        </div>
      );
    }
  }
}

export default EmailTemplate;