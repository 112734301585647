import React, { Component } from 'react';
import {Layout,Stack } from '@shopify/polaris';
import store from 'store2';
import Common from '../../../Helpers/Common';

export default class UpdateProductsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: store('products') || [],
      product_deleted: false
    }
  }
  deleteProduct(item){
    this.setState({
      products: this.state.products.filter(i => i.id !== item.id),
      product_deleted: true
    });
  }

  showImage(product){
    if(product && product.image && product.image.src){
      try{
        var src = product.image.src;
        src = src.replace(".png", "_40x.png")
        .replace(".jpeg", "_40x.jpeg")
        .replace(".jpg", "_40x.jpg")
        .replace(".gif", "_40x.gif");
      }catch(e){
        return null;
      }
      return <img src={src} alt="icon" style={{width:"40px",height:"40px"}}></img>;
    }
    else{
      return null;
    }
  }

  render() {
    if(this.state.product_deleted){
      store('products', this.state.products);
    }
    let products = this.state.products.map((item,i) => {
      return <div key={i}>
        <ul className="selected-products">
          <li className="selected-products-list">
            <div className="float-left update-products-left-section">
            <span className="update-product-img-title-section">
              {this.showImage(item)}
              <div className="update-product--title">{item.title}</div>
            </span>
            </div>
            <div className="float-right update-products--section-right">
              {
                item.handle?
                <div className="edit-update-buttons">
                <a href={Common.getShopBaseUrl()+'/products/'+item.handle} target="_blank" rel="noopener noreferrer">
                &nbsp;[View]
                </a>
                <a href={Common.getShopBaseUrl()+'/admin/products/'+item.id} target="_blank" rel="noopener noreferrer">
                &nbsp;[Edit]
                </a>
                </div>
                :null
              }
              <div className="float-right">
                <button className="btn-remove" onClick={this.deleteProduct.bind(this, item)}>
                &#9747;
                </button>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </li>
        </ul>
      </div> 
    });
    return (
      <Layout>
        <Layout.Section>
          <Stack
            vertical={true}
            spacing="extraTight"
            distribution="equalSpacing"
          >
          {products}
          </Stack>
        </Layout.Section>
      </Layout>
    );
  }
}